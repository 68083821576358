import React from 'react';

import styles from './PerspectiveBar.module.css';

const PerspectiveBar = () => {
  return (
    <>
      <div className={styles.perspectiveBar} />
    </>
  );
};

export default PerspectiveBar;

